:root {
  /* color */
  --font-color-1: #fff;
  --font-color-2: #888888;
  --font-color-3: #4B4B4B;
  --font-color-4: #FFFFFFE6;
  --font-color-5: #707070;
  --font-color-6: #9597A6;
  --font-color-black: #000000;
  --error-font-color: #B8817D;
  --background-color-1: #636780;
  --background-color-2: #fff;
  --theme-color: #545871;
  --line-color: #9597A6;
  --input-border-color: #E6DBDA;
  --border-color: #fff;
  --border-color-2: #CCCCCC;
  --border-shadow-color: #00000029;
  --checkbox-border-color: #fff;
  --checkbox-background-color: #fff;
  --header-shadow-color: #00000029;
  --header-background-color: #fff;
  --project-detail-container-background-color: #FFFFFFE6;
  --project-detail-container-border-color: #F0F1F7;
  --progress-file-form-border-color: #9597A6;
  --progress-input-border-color: #CCCCCC;
  --progress-input-placeholder-color: #C1C1C1;
  --progress-button-red: #FF0000;
  --progress-button-white: #DADBE6;
  --progress-button-grey: #F0F1F7;
  --progress-button-dark-grey: #545871E6;
  --stepper-active-color: #F6474D;
  --stepper-line-color: #9597A6;
  --cancel-button-backgroud-color: #fff;
  --submit-button-color: #F6474D;
  --download-template-button: #0023FF;
  --download-template-dialog-border: #F0F1F7;
  --download-template-dialog-background: #FEFFE9E6;
  --download-template-dialog-shadow: #00000029;

  /* font size */
  /* ボタン */
  --button-big-font-size: 22px;
  --button-medium-font-size: 18px;
  --button-small-font-size: 16px;
  --button-vy-small-font-size: 12px;

  /* 入力欄 */
  --input-font-size: 18px;
  --text-field-label-font-size: 18px;

  /* ヘッダー */
  --header-title-font-size: 22px;

  /* サイドバー */
  --sidebar-menu-font-size: 16px;
  --sidebar-title-font-size: 8px;

  /*　ステッパー */
  --stepper-font-size: 23px;

  /* 上記に該当しないその他(主に文言などのテキスト) */
  --vy-vy-small-font-size: 10px;
  --vy-small-font-size: 12px;
  --small-font-size: 14px;
  --medium-font-size: 18px;
  --big-font-size: 22px;
  --vy-big-font-size: 28px;
  --vy-vy-big-font-size: 34px;
  --title-font-size: 48px;
}

body {
  margin: 0;
  /* TODO: デザインで使用しているフォントに変更する */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
